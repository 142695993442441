<script>
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
// import Skeleton from "@/view/front/components/skeleton";
import moment from "moment";
import Swal from "sweetalert2";

/**
 * page-jobs-new component
 */
export default {
  data() {
    return {
      auth: localStorage.getItem("id_token") !== null ? true : false,
      token: `Bearer ${localStorage.getItem("token")}`,
      user:
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user"))
          : {},
      profile:
        localStorage.getItem("profile") !== null
          ? JSON.parse(localStorage.getItem("profile"))
          : {},
      loading: true,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      keywords: "",
      email: "",
      location: "",
      data: [],
      experiences: [],
      selected_job_experience: [],
      success: false,
    };
  },
  components: {
    Navbar,

    Switcher,
    // Skeleton,
    Footer,
  },
  mounted() {
    this.email = this.auth ? this.user.email : "";
    this.location = this.auth ? this.profile.city : "";
    this.getJobCareerLevel();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    getJobCareerLevel() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-career-levels`).then((res) => {
        res.data.map((c) => {
          if (c.career_level === "Executive & management") {
            c.career_level = "Executive";
          }

          if (c.career_level === "Experienced professional") {
            c.career_level = "Experienced";
          }

          this.experiences.push(c);
        });
      });
    },
    submitJobAlert() {
      this.success = true;
      if (!this.keywords) {
        Swal.fire({
          title: "",
          text: "Please enter keyword!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      if (
        !this.selected_job_experience ||
        this.selected_job_experience == null ||
        this.selected_job_experience == ""
      ) {
        Swal.fire({
          title: "",
          text: "Please select job experience!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      if (!this.location) {
        Swal.fire({
          title: "",
          text: "Please select job location!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      axios
        .post(`${API_URL}/job-alert`, {
          keywords: this.keywords,
          location: this.location,
          career_level: this.selected_job_experience,
          email: this.email,
        })
        .then((res) => {
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 3000);
          if (res.data === "200") {
            Swal.fire({
              title: "",
              text: "Job alert has been saved.",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          } else {
            Swal.fire({
              title: "",
              text: "Some thing went rong",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }
        });
    },
    _jobCareerSelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_experience.includes(event.target.value)) {
          var temp = this.selected_job_experience;
          temp.push(event.target.value);
          this.selected_job_experience = temp;
        }
      } else {
        var temp = [];
        this.selected_job_experience.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_experience = temp;
      }
    },
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="section_ch_p bg-light d-table w-100 mt-10">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-8 pagech1">
            <div class="blog_header pl-0">
              <div class="col-lg-12 p-0 w-100">
                <div class="blog_back">
                  <i class="fas fa-long-arrow-alt-left p-1"></i>
                  <router-link to="/career-resources"
                    >Back to blog list</router-link
                  >
                </div>
              </div>
              <h2 class="m_title">Future Jobs</h2>
              <span class="m_title">by: job4u.pk (3 min read)</span>
            </div>

            <div class="p_about2">
              Whether you’re considering leaving your current job soon, or are
              simply planning your career path in the near future, securing your
              position in future jobs is simply prudent professional behavior.
              The obvious question, then, becomes, how does one go about doing
              so?
            </div>

            <div class="p_about2">
              We’ve spoken about the importance of good networking skills and an
              effective professional network in another article, but we can’t
              stress this enough: having a good network is critical to securing
              your position in future jobs, and the kind of jobs offered to you
              when you re-enter the job market. Your network can directly or
              indirectly link you to better opportunities, and can also act as
              an avenue to connect you with learning and networking channels to
              increase your skillset and the people you have access to. Thus, to
              secure future jobs for yourself, it is of critical importance to
              build an effective network, as well as keep up with it.
            </div>

            <div class="p_about2">
              Another important factor in any decisions for future jobs is that
              of what you gain – you’re leaving a relatively secure position to
              take a risk, so it only makes sense to ensure you stand to gain
              more than what you currently have. Of course, here, it is
              important to look at salary, work environment, benefits, perks,
              hours etc., but it is also important to consider how your role
              will be different. If you’re taking on significantly more stress,
              and gaining moderately better working conditions, it is worth
              analyzing whether the mental toll is worth it. An excellent way to
              do so is to compare your current position with any possible future
              opportunities, and analyze the pros and cons for both. It is only
              feasible to shift when you stand to gain more in the new position
              than what you currently have.
            </div>

            <div class="p_about2">
              One perhaps less considered point is to be open to all
              possibilities – while it is likely that you find an opportunity in
              your field, you may just as well stumble across a role outside
              your current field that is perfect for you. Alternatively, you may
              find openings in areas you had not even considered before (for
              instance, you may be a technical expert, but be offered a teaching
              post), and, if you’re closed off to the option, you risk losing
              out on potentially very good prospects. Thus, keep an open mind
              and, if something sounds interesting and fits your skillset and
              values, don’t be afraid to give it a try! You may end up finding a
              new passion, or, worst case scenario, you’ll know what you simply
              don’t enjoy doing.
            </div>
            <br />
            <!-- <hr> -->
          </div>
          <div class="col-lg-4 col-md-4 mt-3">
            <div class="card">
              <div class="back_overlay">
                <div class="row">
                  <div class="col-lg-6 col-10">
                    <h2 class="">want a great job taillard to your skills ?</h2>
                    <p class="">
                      save time and find higher quality jobs by signing up to
                      job4u.pk
                    </p>
                    <button type="submit" class="btn btn-default join_btn mb-6">
                      <a href="/job-seeker-register">Join job4u.pk now! </a>
                    </button>
                  </div>
                  <div class="col-lg-2 cv_image_bg col-6">
                    <!-- <img src="/images/carer_resorses/CV.png" alt=""> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="card mt-5">
              <div class="card-headerchch">Create a job alert!</div>
              <div class="card-body p-5">
                <form>
                  <div class="form-group">
                    <label class="labal_style" for="kyeword"
                      >keywords <span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      v-model="keywords"
                      class="form-control form-control-sm serach_i_ch"
                      id="kyeword"
                      placeholder="Enter Kyewords"
                    />
                  </div>

                  <div class="m_title pt-1 labal_style">
                    CAREER LEVELS <span style="color: red">*</span>
                  </div>
                  <div class="" style="">
                    <ul class="mt-2">
                      <li v-for="(item, index) in experiences" :key="index">
                        <input
                          type="checkbox"
                          :id="item.career_level"
                          :name="item.career_level"
                          class="custom-control-input"
                          :value="item.career_level"
                          v-on:click="_jobCareerSelection($event)"
                        /><label
                          class="custom-control-label m_title"
                          :for="item.career_level"
                          style="font-size: 13px"
                          >{{ item.career_level }}</label
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <label class="labal_style" for="exampleInputlocalisation1"
                      >Job Location</label
                    >
                    <input
                      type="text"
                      v-model="location"
                      class="form-control form-control-sm serach_i_ch"
                      id="exampleInputlocalisation1"
                      placeholder="localisation"
                    />
                  </div>
                  <div class="form-group">
                    <label class="labal_style" for="exampleInputYouremail1"
                      >Your email<span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      v-model="email"
                      class="form-control form-control-sm serach_i_ch"
                      id="exampleInputYouremail1"
                      placeholder="Youremail"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      class="card-headerch_btn bg_blue_ch"
                      @click="submitJobAlert()"
                      :class="
                        success ? 'spinner spinner-light pinner-right' : ''
                      "
                    >
                      Create job alert!
                    </button>
                    <!-- <div
                      v-if="success"
                      class="alert ml-5"
                      style="
                        background-color: #d4edda;
                        border-color: #d4edda;
                        color: #155724;
                      "
                      role="alert"
                    >
                      Form Submited
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
